<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Create New Subject
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(handleSingleMember)" @reset.prevent="resetForm">



          <b-row>
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing mb-1">
                <b-form-radio v-model="localOptions.rateSingleEmployee" name="some-radios" v-bind:value="true">
                  Single Employee
                </b-form-radio>
                <b-form-radio v-model="localOptions.rateSingleEmployee" name="some-radios" v-bind:value="false">
                  Multiple Employees
                </b-form-radio>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="!localOptions.rateSingleEmployee">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing mb-1">
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_GROUP">
                  Employee by Group
                </b-form-radio>
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_BRANCH">
                  Employee by Branch
                </b-form-radio>
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_DEPARTMENT">
                  Employee by Department
                </b-form-radio>
                <b-form-radio v-model="searchType" name="multiple-employees" value="MODE_POSITION">
                  Employee by Position
                </b-form-radio>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="!(localOptions.rateSingleEmployee) && searchType == 'MODE_GROUP'">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>Select Group</h5>
                <group-picker class="col-12 " :initialCategories="groupSubject" @selectionChanged="valueChanged">
                </group-picker>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!(localOptions.rateSingleEmployee) && searchType == 'MODE_BRANCH'">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>Select Branch</h5>
                <branch-picker class="col-12 " :initialCategories="branchSubject" @selectionChanged="valueChanged">
                </branch-picker>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!(localOptions.rateSingleEmployee) && searchType == 'MODE_DEPARTMENT'">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>Select Department</h5>
                <department-picker class="col-12 " :initialCategories="departmentSubject"
                  @selectionChanged="valueChanged">
                </department-picker>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!(localOptions.rateSingleEmployee) && searchType == 'MODE_POSITION'">
            <!--/ birth date -->
            <b-col md="12">
              <div class="demo-inline-spacing">
                <h5>Select Position</h5>
                <position-picker class="col-12 " :multiple="Boolean(Boolean)" :initialCategories="positionSubject"
                  @selectionChanged="valueChanged">
                </position-picker>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="localOptions.rateSingleEmployee">
            <!-- phone -->

            <b-col class="mt-2" cols="12">
              <h5>Select Employees </h5>

              <member-picker :searchOptions="searchOptions" @selectionChanged="selectionChanged">

              </member-picker>
            </b-col>
          </b-row>
          <b-row v-if="isMultiSelectEmployeeEnabled">
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addAllMembers"
                class="mr-2">
                Add All Members
              </b-button>
              <b-spinner class="mr-1" v-if="loading" variant="warning" />
            </div>
          </b-row>
          <b-row v-if="isMultiSelectEmployeeEnabled">
            <!-- phone -->

            <b-col class="mt-2" cols="12">
              <h5>Select Multiple Employees </h5>

              <member-multi-picker :searchOptions="searchOptions" :searchType="searchType"
                :initialCategories="rateallSubjects" @selectionChanged="multipleEmplooyesChanged">

              </member-multi-picker>
            </b-col>
          </b-row>

          <b-row>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" class="mr-2"
              v-if="!loading && rateallSubjects != null && rateallSubjects.length > 0">
              Add
            </b-button>
            <b-spinner class="mr-1" v-if="loading" variant="warning" />

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              v-if="!loading" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow,
  BCol,
  BCard,
  BFormTextarea,
  BFormRadio,
  BCardText,
  BFormCheckbox, BListGroup, BListGroupItem, BSpinner
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

import router from '@/router'

import MemberPicker from "@/views/members/component/MemberPicker.vue"
import DepartmentPicker from "@/views/department/components/DepartmentPicker.vue"
import BranchPicker from "@/views/branch/component/BranchPicker.vue"
import GroupPicker from "@/views/clientgroups/components/GroupSelector.vue"
import PositionPicker from "@/views/position/component/PositionPicker.vue"
import MemberMultiPicker from "@/views/members/component/MemberMultiPicker.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormRadio,
    BCardText,
    BFormCheckbox,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    MemberPicker, DepartmentPicker,
    BranchPicker,
    GroupPicker,
    PositionPicker,
    MemberMultiPicker,
    BListGroup, BListGroupItem,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {

    isMultiSelectEmployeeEnabled: function () {
      return this.searchType != null & this.searchOptions != null && !this.localOptions.rateSingleEmployee;
    },
    isSingleEmployee: function () {
      return this.localOptions.rateSingleEmployee
    },
    isSingleEmployeeCanAdd: function () {
      var t = this.localOptions.rateSingleEmployee && (this.rateallSubjects != null && this.rateallSubjects.length > 0)
      //console.log(this.rateallSubjects.length);
      //console.log(this.rateallSubjects);

      //console.log(t);
      return t;

    },
    isMultipleEmployee: function () {
      return !this.localOptions.rateSingleEmployee
    },
    showButtons: function () {
      return this.isSingleEmployeeCanAdd || this.isMultipleEmployee
    },
  },
  data() {
    return {
      required,
      alphaNum,
      loading: false,
      email,
      countries,
      rateHigherPositions: false,
      rateLowerPositions: true,
      rateSamePositions: true,
      searchOptions: null,
      searchType: null,
      groupSubject: null,
      branchSubject: null,
      departmentSubject: null,
      positionSubject: null,
      rateallSubjects: [],
      localOptions:
      {
        rateSingleEmployee: true,
        searchOptions: null,
        isMember: true,
        surveyitemtitle: null,
        surveyitemphoto: null,
        surveyitemmail: null,
        surveyitemmobile: null,


      }
    }
  },
  methods: {
    resetForm() {

    },
    addAllMembers() {
      {
        //console.log("add all triggered");
        //console.log("searchType", this.searchType);
        //console.log("search Options", this.searchOptions)
        this.loading = true;



        this.localOptions.groupid = router.currentRoute.query.gid;

        //console.log(this.localOptions);
        var updateData = {
          group: router.currentRoute.query.gid,
          searchType: this.searchType,
          searchOptions: this.searchOptions

        }

        //console.log("sending : ", updateData);
        axios({
          method: "post",
          url: `/admin/f/cm/addAllMemberstoGroupByOption`,
          data: updateData,
        })
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });





            this.isLoading = false;
            this.isUpdated = false;
            this.$emit('new-record-created')
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$emit('refetch-data')
          })
          .catch((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
                text: r.message
              },
            });
            this.isLoading = false;
          });
        return;
        store.dispatch('app-surveys/addSubject', this.localOptions)
          .then((response) => {

            var result = response.data.success;



            if (result != null && result != undefined && result == 100) {


              //console.log(response);


              this.$emit('new-record-created')
              this.$emit('update:is-add-new-user-sidebar-active', false)
              this.$emit('refetch-data')


            } else {
              //console.log(response);

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.loading = false;
            }





          }).catch(() => {
            this.loading = false;
          })
      }
    },
    handleSingleMember() {
      {
        //console.log("add triggered");
        this.loading = true;


        //console.log("add all triggered");
        //console.log("searchType", this.searchType);
        //console.log("search Options", this.searchOptions)
        this.loading = true;



        this.localOptions.groupid = router.currentRoute.query.gid;
        var vMembers = [];

        for (let index = 0; index < this.rateallSubjects.length; index++) {
          const element = this.rateallSubjects[index];
          vMembers.push(element.id);


        }

        //console.log(this.localOptions);
        var updateData = {
          members: vMembers,
          groupId: [router.currentRoute.query.gid]
        }

        //console.log("sending : ", updateData);
        axios({
          method: "post",
          url: `/admin/f/cm/addMemberstoGroup`,
          data: updateData,
        })
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });





            this.isLoading = false;
            this.isUpdated = false;
            this.$emit('new-record-created')
            this.$emit('update:is-add-new-user-sidebar-active', false)
            this.$emit('refetch-data')
          })
          .catch((r) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "AlertOctagonIcon",
                variant: "warning",
                text: r.message
              },
            });
            this.isLoading = false;
          });

        return;
        store.dispatch('app-surveys/addSubject', this.localOptions)
          .then((response) => {

            var result = response.data.success;



            if (result != null && result != undefined && result == 100) {


              //console.log(response);


              this.$emit('new-record-created')
              this.$emit('update:is-add-new-user-sidebar-active', false)
              this.$emit('refetch-data')


            } else {
              //console.log(response);

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.loading = false;
            }





          }).catch(() => {
            this.loading = false;
          })
      }
    },

    selectionChanged(payload) {
      //console.log(payload);
      //console.log("LISTADDNEW");
      //   this.localOptions.rateSubject=payload;
      if (payload == null) {
        this.rateallSubjects = [];

      } else {
        if (this.localOptions.rateSingleEmployee) {
          this.rateallSubjects = [];
        }
        this.rateallSubjects.push(payload);
      }



    },
    valueChanged(payload) {
      //console.log(payload);
      //console.log("option should change");
      if (payload == null) {
        this.searchOptions = null;

      } else {
        this.searchOptions = payload;
      }



    },


    multipleEmplooyesChanged(payload) {
      //console.log(payload);
      this.rateallSubjects = payload;


    },
    SurveyTypeUpdated(payload) {
      //console.log("Catefy result in info ");
      //console.log(payload);
      if (payload == null) {
        this.localOptions.SurveyTypeId = 0;

        this.localOptions.surveyType = null,
          this.localOptions.surveyTypeKey = null
      } else {
        this.localOptions.SurveyTypeId = payload.id;

        this.localOptions.surveyType = payload.title,
          this.localOptions.surveyTypeKey = payload.typekey
      }

    },
    SurveyThemeUpdated(payload) {
      //console.log("Catefy result in info ");
      //console.log(payload);
      if (payload == null) {
        this.localOptions.SurveyTypeId = payload.id;

        this.localOptions.surveyType = payload.title,
          this.localOptions.surveyTypeKey = payload.typekey
      } else {
        this.localOptions.SurveyThemeId = payload.id;

        this.localOptions.surveyThemeText = payload.title,
          this.localOptions.surveyThemeKey = payload.typekey
      }
    },
  },
  setup(props, { emit }) {

    return;



  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>